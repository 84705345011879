import React from "react";
import Template from '../components/Template/Template';
import Faq from '../sections/Faq/Faq';

const Marki = () => {

    return (
        <Template path="/marka-hp">
           
           <h1 className="ch1">HP - skup tonerów</h1>
            <section id="pSell" className="ccon">
                <p>Drukarki firmy Hewlett Packard są bardzo rozpowszechnione &ndash; znajdziemy je i w domach prywatnych, i w firmach. Marka ta często zachęca do zakupu dużej ilości wkładów naraz. Skuszeni atrakcyjnym rabatem, decydujemy się na kilka kartridżów i w efekcie zostajemy z niepotrzebnymi, pełnymi tonerami HP. Jednak zamiast je wyrzucać i w ten sposób narażać na straty nie tylko własny portfel, ale także środowisko, lepiej skontaktować się z nami!</p>
                <p>Prowadzimy skup oryginalnych tonerów HP. Przyjmujemy egzemplarze całkowicie nietknięte, ale także i te, które mają otwarte fabryczne opakowanie. Najważniejsze, by plomba pozostała nienaruszona! Skupujemy pełne wkłady na terenie całej Polski &ndash; w Warszawie odbieramy je sami, w innych miastach wysyłamy kuriera.</p>
            </section>
    

        </Template>
    )
}

export default Marki
